.form__chooseType {
  grid-area: choose-type;
  margin: 40px 0 15px;
  font-size: 1.125rem;
}

@media screen and (max-width: 600px) {
  .form__chooseType {
    margin: 25px 0 15px;
  }
}

.form__radioContainer {
  display: flex;
  grid-area: types;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .form__radioContainer {
    display: flex;
    flex-direction: column;
    margin: 7px 0 7px 3px;
  }
}

.form__radioContainer_1 {
  cursor: pointer;
  font-size: 1.125rem;
  white-space: nowrap;
}

.form__radioContainer_2 {
  cursor: pointer;
  margin: 0 0 0 35px;
  font-size: 1.125rem;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .form__radioContainer_2 {
    margin: 10px 0 0;
  }
}

.form__radio {
  transform: translateY(0.075rem);
  appearance: none;
  transition: all 0.25s ease-out;
  margin: 0 8px 0 0;
  outline: 0.125rem solid var(--color-grey);
  border: 0.32rem solid var(--color-black);
  border-radius: 50%;
  background-color: transparent;
  width: 1rem;
  height: 1rem;
}

.form__radio:hover {
  outline: 0.125rem solid var(--color-accent);
  border: 0.32rem solid var(--color-black);
}

.form__radio:checked {
  outline: 0.125rem solid var(--color-accent);
  border: 0.32rem solid var(--color-black);
  background-color: var(--color-accent);
}

.form__radioLabel {
  transition: all 0.25s ease-out;
  color: var(--color-grey);
}

.form__radioLabel:hover {
  color: var(--color-l-grey);
}

.form__radio:checked ~ .form__radioLabel {
  color: #f0f3f5;
}

@media screen and (min-width: 750px) {
  .page_light .modal .form__radio {
    cursor: pointer;
    border: 0.32rem solid var(--color-white);
  }

  .page_light .modalPrices__itemContainer:hover .form__radio,
  .page_light .modal .form__radioContainer_1:hover .form__radio,
  .page_light .modal .form__radioContainer_2:hover .form__radio {
    outline: 0.125rem solid var(--color-grey);
    border: 0.32rem solid var(--color-white);
  }

  .page_light .modal .form__radioLabel {
    color: var(--color-grey);
  }

  .page_light .modal .form__radioLabel:hover {
    color: var(--color-accent);
  }

  /* .page_light .modal .form__radio:checked ~ .form__radioLabel {
    color: var(--color-black);
  } */
}
