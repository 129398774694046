.modal .modal {
  width: 100%;
}

.modal .modal .modal__container {
  background-color: var(--color-black);
}

.terms__text {
  padding: 20px;
  width: 60%;
  height: 80%;
  margin: auto;
  background: var(--color-black);
  overflow: scroll;
  color: var(--color-m-grey);
  font-size: 16px;
  text-transform: initial;
  white-space: pre-wrap;
  border: 1px solid rgba(187, 161, 135, .1);
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
}

.modal .modal__container.terms, 
.header .modal .modal .modal__container.terms {
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 750px) {
  .terms__text {
    width: 95;
    height: 98%;
  }
}
