.textarea__input:focus ~ .field__label,
.textarea__input:not(:placeholder-shown) ~ .field__label {
  top: -20px;
  left: 0px;
  color: var(--color-grey);
  font-size: 0.95rem;
}

.textarea {
  position: relative;
  grid-area: comment;
  margin: 0 0 24px 20px;
  margin: 30px 0 0 0;
}

.textarea__input {
  position: relative;
  z-index: 2;
  margin: 0 0 8px;
  border: none;
  border-bottom: 1px solid var(--color-grey);
  background: transparent;
  resize: none;
  color: var(--color-grey);
  font-size: 1.125rem;
  line-height: 1.5;
}

@media screen and (min-width: 750px) {
  .modal__container .textarea__input {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .textarea__input {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .textarea__input {
    margin: 0 0 18px;
    width: 95%;
  }
}

.textarea__input:-webkit-autofill,
.textarea__input:-webkit-autofill:hover,
.textarea__input:-webkit-autofill:focus,
.textarea__input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--color-grey) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.textarea__input:focus {
  outline: 1px solid transparent;
  border: none;
  border-bottom: 1px solid var(--color-accent);
}

.textarea__input::placeholder {
  opacity: 0.8;
  cursor: text;
  color: transparent;
}

.textarea__input:invalid {
  border-bottom: 1px solid #d22f2f;
}
.textarea__input:placeholder-shown {
  opacity: 0.8;
  transition: border-bottom 0.25s ease-out;
  border-bottom: 1px solid var(--color-grey);
  color: transparent;
  color: var(--color-grey);
}

.textarea__input:hover:placeholder-shown {
  border-bottom: 1px solid var(--color-l-grey);
}

.textarea__count {
  margin: 0 0 0 auto;
  width: fit-content;
  color: var(--color-grey);
  font: var(--font-xs);
}


.quiz .textarea {
  margin: 15px 0 0 0;
}

.quiz .field.contact {
  margin: 15px 0 0;
}