.form__container {
  grid-gap: 0 20px;
  display: grid;
  grid-template-areas:
    "name contact"
    "comment country"
    "comment choose-type"
    "comment types"
    "agreement agreement"
    "submit submit";
  grid-area: form;
  margin: 0 0 0 10px;
}

@media screen and (max-width: 900px) {
  .form__container {
    grid-gap: 0 0px;
    grid-template-areas:
      "name"
      "contact"
      "country"
      "comment"
      "choose-type"
      "types"
      "agreement"
      "submit";
    margin: 5px 0 0;
  }
}

.prices .form__container.price__options {
  grid-template-areas:
    "price-options price-options"
    "name    contact     "
    "country   comment  "
    "choose-type comment"
    "types     comment      "
    "agreement agreement"
    "submit    submit   ";
  padding: 30px 0 0;
}

@media screen and (max-width: 600px) {
  .prices .form__container.price__options {
    max-height: 70vh;
    overflow: scroll;
    grid-template-areas:
      "price-options"
      "name        "
      "contact     "
      "country"
      "comment"
      "choose-type "
      "types        "
      "agreement "
      "submit       ";
  }
}
