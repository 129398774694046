.field {
  position: relative;
  margin: 30px 0 0 0;
}

.field.name {
  grid-area: name;
}

.field.contact {
  grid-area: contact;
}

.field.country {
  grid-area: country;
}

@media screen and (min-width: 900px) {
  .field.country {
    margin: 30px 0 0 0px;
  }
}

.field__input {
  position: relative;
  z-index: 2;
  margin: 0 0 24px;
  border: none;
  border-bottom: 1px solid var(--color-grey);
  background: transparent;
  color: var(--color-grey);
  font-size: 1.125rem;
  line-height: 1.5;
}

@media screen and (min-width: 750px) {
  .modal__container .field__input {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .field__input {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .field__input {
    margin: 0 0 18px;
    width: 95%;
  }
}

.field__input:-webkit-autofill,
.field__input:-webkit-autofill:hover,
.field__input:-webkit-autofill:focus,
.field__input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--color-grey) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.field__input:focus {
  outline: 1px solid transparent;
  border: none;
  border-bottom: 1px solid var(--color-accent);
}

.field__input::placeholder {
  opacity: 0.8;
  cursor: text;
  color: transparent;
}

.field__input:focus ~ .field__label,
.field__input:not(:placeholder-shown) ~ .field__label {
  top: -20px;
  left: 0px;
  color: var(--color-grey);
  font-size: 0.95rem;
}

.field__input:invalid {
  border-bottom: 1px solid #d22f2f;
}

.field__input:placeholder-shown {
  opacity: 0.8;
  transition: border-bottom 0.25s ease-out;
  border-bottom: 1px solid var(--color-grey);
  color: transparent;
  color: var(--color-grey);
}

.field__input:hover:placeholder-shown {
  border-bottom: 1px solid var(--color-l-grey);
}

.field__label {
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0.8;
  transition: all 0.25s ease-out;
  cursor: text;
  color: var(--color-grey);
  font-size: 1.125rem;
}

@media screen and (max-width: 600px) {
  .field__label {
    font-size: 1rem;
  }
}
