.modalPrices {
  display: none;
  grid-area: price-options;
  flex-direction: column-reverse;
  margin: 40px 0 0;
}

.modal .modalPrices {
  display: flex;
}

.modalPrices__itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 9px;
  margin: 18px 0 0;
  border-bottom: 1px solid var(--color-l-grey);
  padding: 0 0 20px;
}

@media screen and (max-width: 500px) {
  .modalPrices__itemContainer {
    padding: 0 0 20px 3px;
  }
}

.modal-prices__item-container:first-of-type {
  border-bottom: none;
}

/* .modalPrices__input {
    appearance: none;
    transform: translateY(0.075rem);
    width: 1rem;
    height: 1rem;
    border: 0.15rem solid var(--color-grey);
    border-radius: 50%;
    margin: 0;
  }
  
  .modal-prices__input:checked {
    background-color: var(--color-accent);
    border: 0.32rem solid var(--color-white3);
    outline: 0.125rem solid var(--color-accent);
  } */
.modalPrice__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.modalPrices__title {
  margin: 0;
  font: var(--font-h-s);
  font-size: 1.375rem;
  text-transform: capitalize;
}
.modalPrices__number {
  margin: 0 10px 0 auto;
  font: var(--font-h-m);
}
.modalPrices__symbol {
  padding: 0 0 0 7px;
  color: var(--color-grey);
  font: var(--font-xs);
}

@media screen and (max-width: 600px) {
  .modalPrices__title {
    font: var(--font-ml);
  }
  .modalPrices__number {
    font: var(--font-ml);
  }
  .modalPrices__symbol {
    font: var(--font-ml);
  }
}
