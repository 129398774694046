@keyframes fade-in-form {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes fade-out-form {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.modal .form {
  position: absolute;
  right: 0;
  padding: 0px;
  width: fit-content;
}

@media screen and (min-width: 750px) {
  .modal.active .form {
    animation: fade-in-form 0.5s ease-out;
  }

  .modal .form {
    animation: fade-out-form 0.5s ease-out;
  }
}

.modal.active .form__subtitle {
  max-width: 100%;
}

.page_light .modal .form .form__subtitle {
  color: var(--color-dark-grey);
}

@media screen and (max-width: 750px) {
  .page_light .modal .form .form__subtitle {
    color: var(--color-white);
  }
}

.modal.active .form__wrapper,
.modal.hidden .form__wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: auto;
  padding: 40px 20px;
}

@media screen and (min-width: 750px) {
  .modal.active .form__wrapper,
  .modal.hidden .form__wrapper {
    height: 90%;
  }
}

@media screen and (max-width: 750px) {
  .prices .modal.active .form__wrapper  {
    border: 1px solid var(--color-dark-grey);
  }
  
}

.modal__container {
  display: flex;
  flex-direction: column;
  transition: background-color 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  overflow-y: scroll;
}

.modal__container .form__submitWrapper {
  position: absolute;
  right: 0;
  bottom: 0px;
  cursor: pointer;
  margin: 60px 0 0;
  background: var(--color-accent);
  padding: 18px 0;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .modal__container .form__submitWrapper {
    position: static;
    background: transparent;
    padding: 18px 0 35px;
  }
}

.modal__container .form__submitContainer {
  justify-content: center;
  margin: 0 auto;
  border-bottom: 1px solid var(--color-white);
  background: var(--color-accent);
  padding: 0 0 5px;
  color: var(--color-white);
}

@media screen and (max-width: 750px) {
  .modal__container .form__submitContainer {
    margin: 0;
    background: transparent;
  }
}

.modal__container .form__submitContainer:hover {
  border-bottom: 1px solid var(--color-black);
}
.modal__container .form__submitContainer:hover .form__submit {
  color: var(--color-black);
}
.modal__container .form__submitArrow:after {
  background-image: url(../../../shared/images/right-arr_black.webp);
  background-color: var(--color-accent);
}

@media screen and (max-width: 500px) {
  .modal__container .form__submitContainer:hover {
    border-bottom: 1px solid var(--color-accent);
  }
  .modal__container .form__submitContainer:hover .form__submit {
    color: var(--color-accent);
  }
  .modal__container .form__submitArrow:after {
    background-image: url(../../../shared/images/right-arr_hover.webp);
    background-color: transparent;
  }
}

.modal.active .form__submit {
  padding: 0 10px 0 0;
  color: var(--color-white);
}

modal.active .form__subtitle {
  width: 100%;
}

.form__submitArrow {
  height: 12.8px;
}

.page_light .modal.active .form {
  padding: 0;
}

@media screen and (max-width: 500px) {
  .page_light .modal.active .form {
    padding: 40px 0;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 750px) {
  .modal__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal__container .form {
    display: flex;
    padding: 0 15px;
    height: 100vh;
    overflow: scroll;
  }

  .modal .form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 180px 46px 180px;
  }

  .modal .form__wrapper {
    padding: 100px 0;
  }

  .mainPage .form__wrapper {
    padding: 180px 0;
  }
}
